<template>
    <div class="out-wrap" v-show="dialogFlag">
        <div v-show="dialogFlag" class="wrap">
            <div class="top">
                <div class="title">推广单品</div>
                <span @click="closeDialog"><i class="el-icon-close" /></span>
            </div>
            <div class="tab-wrap">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane key="text" label="分享文案" name="text" />
                    <el-tab-pane key="link" label="推广链接" name="link" />
                    <el-tab-pane key="image" label="二维码海报图" name="image" />
                    <el-tab-pane key="command" :label="bizTypeDiffObj.commandType" name="command" v-if="kouling" />
                </el-tabs>
            </div>

            <div class="share-text" v-show="textFlag">
                <div class="left-wrap">
                    <div class="img-wrap" ref="goodsImg">
                        <img :src="goodsImg" alt="">
                    </div>
                    <div class="button" @click="copyGoodsImg">复制图片</div>
                </div>
                <div class="right-wrap">
                    <div class="info-wrap" ref="shareText">
                        <div class="title">{{ bizTypeDiffObj.titleTag }}{{ goodsTitle }}</div>
                        <template v-if="groupTye === 1">
                            <template v-if="depositHandPrice">
                                <div class="price">【预定价】<span>￥{{ depositHandPrice }}</span></div>
                                <div class="price"> {{ bizTypeDiffObj.priceType }} <span>￥{{ salePrice }}（{{ depositLabel }}）</span></div>
                            </template>
                            <template v-else-if="couponAmount ">
                                <div class="price">【在售价】<span>￥{{ salePrice }}</span></div>
                                <div class="price">【券后价】<span>￥{{ couponPrice }}</span></div>
                            </template>
                            <template v-else>
                                <div class="price">【正品低价】<span>￥{{ salePrice }}&nbsp;</span><span v-html="unitPriceStrInHtml" /></div>
                            </template>
                        </template>
                        <template v-if="groupTye === 2">
                            <div class="price">【单买价】<span>￥{{ suggestPrice }}</span></div>
                            <div class="price">【拼团价】<span>￥{{ salePrice }}&nbsp;</span><span v-html="unitPriceStrInHtml" /></div>
                        </template>
                        <div class="link">【购买链接】{{ shortShareLink }}</div>
                    </div>
                    <div class="button" @click="copyText">
                        复制文案
                    </div>
                </div>
            </div>
            <div class="share-link" v-show="linkFlag">
                <div class="left-wrap">
                    <div class="img-wrap" ref="linkImg">
                        <img :src="goodsImg" alt="">
                    </div>
                    <div class="button" @click="copylinkImg">复制图片</div>
                </div>
                <div class="right-wrap">
                    <div class="short-link">
                        <div class="desc">短链接</div>
                        <div class="bottom">
                            <div class="input">
                                {{ shortShareLink }}
                            </div>
                            <div class="button" @click="copyShortLink">复制链接</div>
                        </div>
                    </div>
                    <div class="long-link">
                        <div class="desc">长链接</div>
                        <div class="bottom">
                            <div class="input">
                                {{ shareLink }}
                            </div>
                            <div class="button" @click="copyLongLink">复制链接</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="share-image" v-show="imgFlag">
                <div class="img-wrap" ref="shareImg">
                    <img :src="shotUrl" alt="">
                </div>
                <div class="button" @click="copyShareImg">复制图片</div>
            </div>

            <div class="share-text" v-show="commandFlag" v-if="kouling">
                <div class="left-wrap">
                    <div class="img-wrap" ref="koulingImg">
                        <img :src="goodsImg" alt="">
                    </div>
                    <div class="button" @click="copykoulingImg">复制图片</div>
                </div>
                <div class="right-wrap">
                    <div class="info-wrap" ref="kouling">
                        <div class="title">{{ bizTypeDiffObj.titleTag }}{{ goodsTitle }} </div>
                        <template v-if="groupTye === 1">
                            <template v-if="depositHandPrice">
                                <div class="price">【预定价】<span>￥{{ depositHandPrice }}</span></div>
                                <div class="price">  {{ bizTypeDiffObj.priceType }} <span>￥{{ salePrice }}（{{ depositLabel }}）</span></div>
                            </template>
                            <template v-else-if="couponAmount ">
                                <div class="price">【在售价】<span>￥{{ salePrice }}</span></div>
                                <div class="price">【券后价】<span>￥{{ couponPrice }}</span></div>
                            </template>
                            <template v-else>
                                <div class="price">【正品低价】<span>￥{{ salePrice }}&nbsp;</span><span v-html="unitPriceStrInHtml" /></div>
                            </template>
                        </template>
                        <template v-if="groupTye === 2">
                            <div class="price">【单买价】<span>￥{{ suggestPrice }}</span></div>
                            <div class="price">【拼团价】<span>￥{{ salePrice }}&nbsp;</span><span v-html="unitPriceStrInHtml" /></div>
                        </template>
                        <div class="link">
                            【购买链接】
                            <div class="kouling">
                                ￥{{ kouling }}￥
                            </div>
                            {{ shortShareLink }}{{ resetKoulingTitle }}，复制这段描述后，打开{{ bizTypeDiffObj.appName }} 
                        </div>
                    </div>
                    <div class="button" @click="copyCommand">
                        复制文案
                    </div>
                    <div class="remark">不要更改文案中的{{ bizTypeDiffObj.commandType }}和活动链接哦~</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Bus from '../../utils/bus.js';
import { snapGoodsHtml } from '../../utils/snap-html.js';
import generateQRCode from '../../utils/generate-qrcode.js';
import copy from '../../utils/copy.js';

export default {
    name: 'goodsDialog',
    data() {
        return {
            dialogFlag: false,
            textFlag: true,
            linkFlag: false,
            imgFlag: false,
            commandFlag: false,
            activeName: 'text',
            goodsInfo: null,
            goodsImg: '',
            goodsTitle: '',
            salePrice: '',
            shortShareLink: '',
            shareLink: '',
            miniShareLink: '',
            qrcode: '',
            shotUrl: '',
            kouling: '',
            lastCategoryName: '',
            brandName: '',
            introduce: '',
            depositHandPrice: '',
            couponPrice: '',
            couponAmount: '',
            depositPrice: '',
            depositLabel: '',
            newUserPrice: '',
            unitPriceStrInHtml: '',
            groupTye: 1,
            suggestPrice: '',
            shareUrl: null,
            memberCount: 0,
            bizType: 0
        };
    },
    computed: {
        bizTypeDiffObj() {
            return {
                titleTag: this.bizType === 1 ? '【猫享自营】' : '【考拉海购】',
                priceType: this.bizType === 1 ? '【猫享价】' : '【考拉价】',
                appName: this.bizType === 1 ? '天猫App' : '考拉海购APP',
                commandType: this.bizType === 1 ? '猫享口令' : '考拉口令'
            };
        },
        resetKoulingTitle() {
            if(this.brandName || this.lastCategoryName){
                return `【${this.brandName}${this.lastCategoryName}】`;
            }
            return '';
        }
    },
    created() {
        /* eslint-disable */
        Bus.$on('showDialog', (data) => {

            this.goodsInfo = data.data.data.goods;
            this.shareUrl = data.data.data.shareUrl;
            this.groupTye = data.groupTye || 1;

            if(this.goodsInfo) {
                this.handleGoodsInfo(this.goodsInfo);
            }
            if(this.shareUrl) {
                this.shortShareLink = this.shareUrl.shortShareLink;
                this. shareLink = this.shareUrl. shareLink;
                this.miniShareLink =this.shareUrl.miniShareLink;
                this.memberCount = this.shareUrl.memberCount;
            }
            if(this.goodsInfo && !this.shareUrl){
                this.shortShareLink = this.goodsInfo.shortShareLink;
                this.shareLink = this.goodsInfo.shareLink;
                this.miniShareLink = this.goodsInfo.miniShareLink;
            }
            this.qrcode = generateQRCode(this.miniShareLink);
            this.couponPrice = (this.salePrice - this.couponAmount).toFixed(2);
            if(this.depositHandPrice) {
                let delta = Math.floor(this.salePrice - this.depositHandPrice);
                if(delta === 0) {
                    this.depositLabel = `预付${this.depositPrice}元抢先定`;
                } else {
                    this.depositLabel = `预付${this.depositPrice}元立减${delta}元`;
                }
            }
            snapGoodsHtml(
                {
                    goods: this.goodsInfo,
                    qrcode: this.qrcode,
                    shareUrl: this.shareUrl,
                    goodsType:  this.groupTye
                }
            ).then((e) => {
                this.shotUrl = e;
            });
            this.dialogFlag = true;
        });
        /* eslint-disable */
    },
    methods: {
        handleGoodsInfo(goodsInfo) {
            this.bizType = goodsInfo.bizType || 0;
            this.kouling = goodsInfo.kouling || '';
            this.brandName = goodsInfo.brandName;
            this.lastCategoryName =  goodsInfo.lastCategoryName
            this.goodsImg = goodsInfo.imageList[0] || '';
            this.goodsTitle = goodsInfo.goodsTitle;
            this.salePrice = goodsInfo.salePrice;
            this.depositPrice = goodsInfo.depositPrice || '' ;
            this.depositHandPrice = goodsInfo.depositHandPrice || '';
            this.suggestPrice= goodsInfo.suggestPrice || '';
            this.couponAmount = goodsInfo.couponAmount || '';
            this.newUserPrice = goodsInfo.newUserPrice || '';
            const { comboInfo } = goodsInfo;
            if(comboInfo && comboInfo.amount && comboInfo.unit && comboInfo.amount > 1) {
                if(comboInfo.unitPrice) {
                    this.unitPriceStrInHtml = `单${comboInfo.unit}约&yen;${comboInfo.unitPrice}`;
                }
            }
        },
        /* eslint-disable */
        handleClick() {
            if(this.activeName === 'text') {
                this.textFlag = true;
                this.linkFlag = false;
                this.imgFlag = false;
                this.commandFlag = false;
            }
            if(this.activeName === 'link') {
                this.textFlag = false;
                this.linkFlag = true;
                this.imgFlag = false;
                this.commandFlag = false;
            }
            if(this.activeName === 'image') {
                this.textFlag = false;
                this.linkFlag = false;
                this.imgFlag = true;
                this.commandFlag = false;
            }
             if(this.activeName === 'command') {
                this.textFlag = false;
                this.linkFlag = false;
                this.imgFlag = false;
                this.commandFlag = true;
            }
        },
         /* eslint-disable */
        closeDialog() {
            this.dialogFlag = false;
            this.textFlag = true;
            this.linkFlag = false;
            this.imgFlag = false;
            this.commandFlag = false;
            this.activeName = 'text';
        },
        copyShortLink() {
            copy(this.shortShareLink,true).then(() => {
                this.successToast();
            })
        },
        copyLongLink() {
            copy(this.shareLink,true).then(() => {
                this.successToast();
            })
        },
        copyText() {
            copy(this.$refs.shareText,false).then(() => {
                this.successToast();
            })
        },
        copyCommand() {
            // const str  = `￥${this.kouling}￥【${this.brandName}${this.lastCategoryName}】，
            // 复制这段描述后，打开考拉海购APP`;
            // copyText(str).then(() => {
            //     this.successToast();
            // })
            copy(this.$refs.kouling,false).then(() => {
                this.successToast();
            })
        },
        copyGoodsImg() {
            copy(this.$refs.goodsImg,false).then(() => {
                this.successToast();
            })
        },
        copyShareImg() {
            copy(this.$refs.shareImg,false).then(() => {
                this.successToast();
            })
        },
        copylinkImg() {
            copy(this.$refs.linkImg,false).then(() => {
                this.successToast();
            })
        },
        copykoulingImg() {
            copy(this.$refs.koulingImg,false).then(() => {
                this.successToast();
            })
        },
        successToast() {
             this.$message({
                message: '复制成功',
                type: 'success'
            });
        }
    }
};
</script>

<style scoped lang='scss'>
.out-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 1007;
}
.wrap {
    width: 786px;
    height: 594px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .top {
        display: flex;
        justify-content: space-between;
        margin: 16px 18px;
        font-size: 16px;
        font-weight: 700;
    }
    .tab-wrap{
        margin: 0 30px;
    }
    .share-text, .share-link {
        margin: 10px 30px;
        display: flex;
        justify-content: space-between;
        .left-wrap {
            .img-wrap {
                width: 189px;
                height: 189px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                }
            }
            .button {
                margin: 0 auto;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 32px;
                border: 1px solid #FF1E32;
                border-radius: 21px;
                color: #FF1E32;
                cursor: pointer;
                &:hover {
                    color: #fff;
                    background: #FD1E32;
                    border: 1px solid #FD1E32;
                }
            }
        }

    }
    .share-text {
        .right-wrap {
            .info-wrap {
                height: 190px;
                width: 521px;
                background: #F9F9F9;
                border: 1px solid #e3e3e3;
                border-radius: 8px;
                padding-top: 18px;
                padding-left: 8px;
                padding-right: 8px;
                .title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 10px;
                }
                .price {
                    margin-bottom: 10px;
                }
                .kouling {
                    padding-top: 10px;
                    padding-left: 5px;
                }
            }
            .remark {
                color: #999;
                font-size: 12px;
                width: 521px;
                text-align: center;
                padding-top: 8px;
            }
            .form-wrap {
                padding-top: 30px;
                padding-left: 20px;
                .button {
                    margin-left: 30px;
                }
            }
            .button {
                margin: 0 auto;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 32px;
                border: 1px solid #FF1E32;
                border-radius: 21px;
                color: #FF1E32;
                cursor: pointer;
                &:hover {
                    color: #fff;
                    background: #FD1E32;
                    border: 1px solid #FD1E32;
                }
            }
        }
    }
    .share-link {
        .right-wrap {
            width: 520px;
            .short-link, .long-link {
                //margin-left: 5px;

                margin-bottom: 20px;
                .desc {
                    margin-bottom: 10px;
                }
                .bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .input {
                        width: 387px;
                        height: 40px;
                        line-height: 40px;
                        background: #F9F9F9;
                        border: 1px solid #E3E3E3;
                        border-radius: 8px;
                        font-size: 14px;
                        color: #303133;
                        padding-left: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        padding-right: 10px;
                        padding-left: 10px;
                    }
                    .button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 120px;
                        height: 32px;
                        border: 1px solid #FF1E32;
                        border-radius: 21px;
                        color: #FF1E32;
                        cursor: pointer;
                        &:hover {
                            color: #fff;
                            background: #FD1E32;
                            border: 1px solid #FD1E32;
                        }
                    }
                }
            }
        }
    }
    .share-image {
        width: 100%;
        padding-top: 20px;
        .img-wrap {
            width: 240px;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }
        }
        .button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 32px;
            border: 1px solid #FF1E32;
            border-radius: 21px;
            color: #FF1E32;
            margin: 0 auto;
            margin-top: 21px;
            cursor: pointer;
            &:hover {
                color: #fff;
                background: #FD1E32;
                border: 1px solid #FD1E32;
            }
        }
    }
}
</style>
