<template>
    <div class="dialog-wrapper">
        <el-dialog
            title="批量转链"
            :visible.sync="visible"
            :show-close="false"
        >
            <div class="top">
                <form>
                    <input type="file" id="fileExport" @change="handleFileChange" ref="inputer" class="input">
                </form>
                <el-button type="plain" @click="upload" class="upload">上传文件</el-button>
                <a href="https://kaola-haitao.oss.kaolacdn.com/9387839e-03a6-48a1-8eb5-93a52b2cdca9.xlsx" download class="download">下载模板</a>
                <div class="fileName" v-if="fileName">{{ fileName }}</div>
            </div>

            <p>1.请先下载表格模板，填完表格信息后上传excel表格文件</p>
            <p>2.点击确定按钮，链接转换完成会自动下载到您的电脑</p>
            <p>3.单次最多上传50条链接</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeDialog">取消</el-button>
                <el-button type="primary" @click="handleConfirm">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
//import axios from 'axios';
import getSuffix from '../../utils/getSuffix.js';

export default {
    data() {
        return {
            successFlag: false,
            file: '',
            fileName: '',
            downloadFlag: false
        };
    },
    props: {
        visible: Boolean
    },
    methods: {
        upload() {
            let inputDOM = this.$refs.inputer;
            inputDOM.click();
        },
        async handleFileChange() {
            let inputDOM = this.$refs.inputer;
            const file = inputDOM.files[0];
            if(file) {
                if(getSuffix(file.name) != '.xlsx') {
                    this.$message.error('请上传表格文件');
                    return false;
                }
                this.successFlag = true;
                this.fileName = file.name;
                this.$message({
                    message: '文件上传成功',
                    type: 'success'
                });
                this.file = file;
            } 
        },
        async handleConfirm() {
            if(!this.file) {
                this.$message.error('请选择表格文件');
                return;
            }
            let formData = new FormData();
            formData.append('file', this.file);


            // axios.defaults.xsrfHeaderName = 'x-csrf-token';
            // axios.defaults.xsrfCookieName = 'csrfToken';
            // axios.defaults.timeout = 120 * 1000;
            // axios.defaults.withCredentials = true;
            // axios({
            //     url: 'https://cps.kaola.com/zhuanke/share/batchConvertLink',
            //     method: 'post',
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //         'X-Requested-With': 'XMLHttpRequest'
            //     },
            //     data: formData
            // }).then((data) => {
            //     const info = data.data;
            //     if(info.retCode === 200) {
            //         window.open(info.data);
            //         this.$emit('successUpload');
            //     }else {
            //         this.$message.error(info.retDesc);
            //     }
            // });
            this.downloadFlag = false;
            this.$message({
                type: 'success',
                message: '转链结果生成中，请稍等'
            });
            const data =await this.$request({
                url: 'https://cps.kaola.com/zhuanke/share/batchConvertLink',
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
                origin: true
            });
            if(data) {
                this.downloadFlag = true;
            }
            if(data.retCode === 200) {
                window.open(data.data);
                this.$emit('successUpload');
            }else {
                this.$message.error(data.retDesc);
            }

            setTimeout(() => {
                if(!this.downloadFlag) {
                    this.$message.error('文件解析失败，请重新上传');
                }
            }, 10*1000);
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
};
</script>

<style lang="scss" scoped>
.dialog-wrapper {
    .top {
        margin-bottom: 30px;
        .input {
            display: none;
        }
        .fileName {
            margin-top: 10px;
            color: #008cff;
        }
        
    }
    .upload {
        margin-right: 20px;
    }
    .download{
        text-decoration: underline;
    }
}
</style>