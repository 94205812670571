<template>
    <div class="transform-wrapper">
        <div class="textarea-wrapper">
            <div class="title">链接</div>
            <textarea
                class="textarea"
                :class="{ noLink: nolinkflag }"
                placeholder="请将您想要推广的考拉或天猫链接黏贴到这里，转换后即可生成您的跟单链接哦"
                v-model="originText"
            />
        </div>
        <p v-if="nolinkflag" class="nullRemark">请输入链接</p>
        <div class="remark">
            <p>
                *
                支持转换的链接包括：考拉/天猫首页、商品详情页、频道页、活动页等考拉/天猫链接
            </p>
            <p>* 转链输入框一次仅支持转换一个链接，请勿把多个链接同时填入</p>
        </div>
        <div class="promotion-wrap">
            <span>推广位：</span>
            <el-select
                v-model="value"
                filterable
                placeholder="请选择"
                :class="{ noPromotion: noPromotionFlag }"
            >
                <el-option
                    v-for="item in options"
                    :key="item.positionId"
                    :label="item.name"
                    :value="item.positionId"
                />
            </el-select>
            <span class="new" @click="showDialog">新建推广位</span>
        </div>
        <p v-if="noPromotionFlag" class="nullRemark">请选择推广位</p>
        <div class="dialog-wrap">
            <el-dialog
                title="新建推广位"
                :visible.sync="dialogVisible"
                :show-close="false"
                width="400px"
            >
                <el-form ref="form" label-width="100px">
                    <el-form-item label="推广类型">
                        <el-radio-group
                            v-model="type"
                        >
                            <el-radio :label="1">一般推广</el-radio>
                            <el-radio :label="2">内容媒体推广</el-radio>
                        </el-radio-group>
                        <el-select
                            placeholder="请选择"
                            v-if="type === 2"
                            :value="2"
                        >
                            <el-option label="抖音" :value="2">
                                抖音
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="推广位名称">
                        <el-input v-model="input" placeholder="推广位名称" />
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="createPosition">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="button">
            <el-button type="primary" @click="transformLink">
                转换推广链接
            </el-button>
        </div>
        <goods-dialog />
        <activityDialog />
        <linkDialog
            :visible="linksDialogFlag"
            @successUpload="successUpload"
            @closeDialog="closeDialog"
        />
        <div class="links" @click="showLinksDialog">批量转链</div>
    </div>
</template>

<script>
//import storage from '../../utils/storage.js';
import extractUrlFromText from '../../utils/extractUrlFromText.js';
import Bus from '../../utils/bus.js';
import goodsDialog from '../../components/goodsPromotion/goodsDialog.vue';
import activityDialog from '../../components/activityPromotion/activityDialog.vue';
import linkDialog from '../../components/transformURL/linkDialog.vue';

export default {
    name: 'transformLink',
    data() {
        return {
            originText: '',
            value: '',
            options: [],
            dialogVisible: false,
            input: '',
            type: 1,
            nolinkflag: false,
            noPromotionFlag: false,
            positionId: '',
            linksDialogFlag: false
        };
    },

    components: {
        goodsDialog,
        activityDialog,
        linkDialog
    },

    async created() {
        //this.value = storage.get('position')?storage.get('position'):'';
        await this.getCorpZhuankePromotionPosition();
    },
    methods: {
        async getCorpZhuankePromotionPosition() {
            const data = await this.$request({
                url: '/api/promotion/getCorpZhuankePromotionPosition'
            });
            if (data && data.length !== 0) {
                this.options = data;
                this.value = data[0].positionId;
            }
        },
        async createPosition() {
            if (!this.input) {
                this.$message.error('请填写推广位名称');
                return;
            }

            const data = await this.$request({
                method: 'post',
                url: '/api/promotion/createZhuankePromotionPosition',
                params: {
                    positionName: this.input,
                    type: this.type
                },
                origin: true
            });
            if (data.code === 200) {
                this.value = data.data;
                this.getCorpZhuankePromotionPosition();
                this.input = '';
                this.positionId = data.data;
                this.dialogVisible = false;
                this.$message({
                    message: '推广位创建成功',
                    type: 'success'
                });
            } else {
                this.$message.error(data.desc);
                this.dialogVisible = false;
            }
        },
        showDialog() {
            this.dialogVisible = true;
        },
        async transformLink() {
            this.originText
                ? (this.nolinkflag = false)
                : (this.nolinkflag = true);
            this.value
                ? (this.noPromotionFlag = false)
                : (this.noPromotionFlag = true);
            if (!this.nolinkflag && !this.noPromotionFlag) {
                const originUrl = extractUrlFromText(this.originText);
                const dialogInfo = await this.$request({
                    url: '/api/tools/convertLink',
                    params: {
                        originUrl,
                        positionId: this.value
                    },
                    origin: true
                });
                const data = dialogInfo.data;
                if (dialogInfo.code == 200 && data) {
                    if (data.type === 1) {
                        Bus.$emit('showDialog', {
                            data: {
                                data
                            }
                        });
                    }
                    if (data.type === 2) {
                        Bus.$emit('showActivityDialog', {
                            data: data.info
                        });
                    }
                } else {
                    this.$message.error('链接无效，请检查');
                }
            }
        },
        showLinksDialog() {
            this.linksDialogFlag = true;
        },
        closeDialog() {
            this.linksDialogFlag = false;
        },
        successUpload() {
            this.linksDialogFlag = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.transform-wrapper {
    position: relative;
    padding: 16px;
    background: #fff;
    .textarea-wrapper {
        margin-top: 15px;
        .title {
            margin-bottom: 10px;
            font-size: 16px;
        }
    }
    .links {
        position: absolute;
        top: 30px;
        right: 30px;
        color: #ff0000;
        cursor: pointer;
    }
    .textarea {
        padding: 13px 24px;
        width: 80%;
        height: 200px;
        color: #333;
        line-height: 30px;
        background: #f7f7f7;
        border-radius: 4px;
        border: none;
        outline: none;
        resize: none;
        font-size: 14px;

        &::-webkit-input-placeholder {
            color: #c0c0c0;
        }
    }
    .noLink,
    .noPromotion {
        border: 1px solid #ff0000;
        border-radius: 4px;
    }
    .nullRemark {
        color: #ff0000;
        margin-top: 10px;
    }
    .remark {
        margin-top: 15px;
        line-height: 20px;
        height: 40px;
        color: #999;
    }
    .promotion-wrap {
        margin-top: 20px;
        .new {
            margin-left: 15px;
            color: #ff0000;
            cursor: pointer;
        }
    }
    .button {
        margin-top: 20px;
    }
}
</style>
